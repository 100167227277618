import { USERTYPE } from '@shared/enums';

export class AuthUtils {
  /**
   * Decodes a token
   * @param token The token to decode
   * @returns The decoded token or null if the token is invalid
   */
  static _decodeToken(token: any): any {
    try {
      const base64Url = token.split('.')[1];
      let base64 = base64Url.replace('-', '+').replace('_', '/');

      // Handle padding for base64 string
      switch (base64.length % 4) {
        case 2:
          base64 += '==';
          break;
        case 3:
          base64 += '=';
          break;
      }

      return JSON.parse(window.atob(base64));
    } catch (e) {
      return null;
    }
  }

  /**
   * Checks if the token is expired
   * @param token The token to check
   * @param offsetSeconds Optional offset in seconds to adjust expiration
   * @returns True if the token is expired, false otherwise
   */
  static isTokenExpired(token: string, offsetSeconds?: number): boolean {
    // Return true if there is no token
    if (!token) {
      return true;
    }

    // Get the expiration date
    const date = this._getTokenExpirationDate(token);

    // Default offsetSeconds to 0 if not provided
    offsetSeconds = offsetSeconds || 0;

    if (date === null) {
      return true;
    }

    // Check if the token is expired
    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  /**
   * Gets the token expiration date
   * @param token The token to get the expiration date from
   * @returns The expiration date or null if not found
   */
  private static _getTokenExpirationDate(token: string): Date | null {
    // Decode the token
    const decodedToken = this._decodeToken(token);

    // Return null if the token is invalid or missing 'exp' field
    if (!decodedToken || !decodedToken.exp) {
      return null;
    }

    // Convert the expiration date from seconds to Date object
    const date = new Date(0);
    date.setUTCSeconds(decodedToken.exp);

    return date;
  }

  /**
   * Routes the user based on their user type
   * @param userType The user type
   * @returns route link
   */
  static getUserDashboardLink(userType: number): string {
    switch (userType) {
      case USERTYPE.Internal:
        return '/portal/uva-team';
      case USERTYPE.Tenant:
        return '/portal/embassy';
      case USERTYPE.Client:
        return '/portal/customer';
      default:
        return '/';
    }
  }
}
