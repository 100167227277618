import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext, NgxsOnInit } from '@ngxs/store';
import { GoogleSignInSuccess, Login, Logout, SignInWithGoogle } from './auth.actions';
import { catchError, switchMap, tap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '@shared/services/user/user.service';
import { ICountry, IUser } from '@shared/models';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '../components/auth.config';
import { AuthGoogleService } from '../services/auth.google.service';

export interface AuthStateModel {
  token: string | null;
  user: IUser | null;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    user: null,
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    this.initConfiguration();
    const token = this.authService.accessToken;
    const userString = localStorage.getItem('auth.user');
    const user = userString ? JSON.parse(userString) : null;
    if (token) {
      ctx.patchState({
        token,
      });
    } else {
      localStorage.removeItem('auth.token');
    }

    if (user) {
      ctx.patchState({
        user: user,
      });
    } else {
      localStorage.removeItem('auth.user');
    }
  }

  @Selector()
  static getState(state: AuthStateModel) {
    return state;
  }

  @Selector()
  static getToken(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static getUser(state: AuthStateModel): IUser | null {
    return state.user;
  }

  @Selector()
  static getUserCountry(state: AuthStateModel): ICountry | null {
    return state.user?.countries[0] ?? null;
  }

  @Selector()
  static getIsAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  constructor(
    private authService: AuthService,
    private googleAuthService: AuthGoogleService,
    private userService: UserService,
    private oAuthService: OAuthService,
  ) {}

  initConfiguration() {
    this.oAuthService.configure(authConfig);
    this.oAuthService.setupAutomaticSilentRefresh();
    return this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (this.oAuthService.hasValidAccessToken()) {
        const googleToken = this.oAuthService.getIdToken();

        if (googleToken) {
          return this.googleAuthService.authenticateGoogleSignIn(googleToken);
        }
      }
      return null;
    });
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.authService.login(action.payload).pipe(
      switchMap((res: any) => {
        ctx.patchState({ token: res.accessToken });
        return this.userService.getUserById(res.userId).pipe(
          tap((user: IUser) => {
            if (user.countries.length > 0) {
              const code = user.countries[0].code?.toLowerCase();
              const flag = `https://flagcdn.com/w40/${code}.png`;
              user.countries[0].flag = flag;
            }
            ctx.patchState({ user });
          }),
          catchError((error) => {
            return throwError(() => new Error(error.message || 'Failed to fetch user'));
          }),
        );
      }),
      catchError((error) => {
        return throwError(() => new Error(error.message || 'Login failed'));
      }),
    );
  }

  @Action(SignInWithGoogle)
  signInWithGoogle() {
    this.oAuthService.initLoginFlow();
  }

  @Action(GoogleSignInSuccess)
  googleSignInSuccess(ctx: StateContext<AuthStateModel>, action: GoogleSignInSuccess) {
    ctx.patchState({ token: action.payload.token });
    return this.userService.getUserById(action.payload.userId).pipe(
      tap((user: IUser) => {
        if (user.countries.length > 0) {
          const code = user.countries[0].code?.toLowerCase();
          const flag = `https://flagcdn.com/w40/${code}.png`;
          user.countries[0].flag = flag;
        }
        ctx.patchState({ user });
      }),
      catchError((error) => {
        return throwError(() => new Error(error.message || 'Failed to fetch user'));
      }),
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    const token = this.oAuthService.getIdToken();
    ctx.setState({
      token: null,
      user: null,
    });
    if (token) {
      this.oAuthService.revokeTokenAndLogout();
      this.oAuthService.logOut();
    }
  }
}
